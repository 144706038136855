<template>
    <div>
        <v-layout wrap pt-lg-16>
            <v-flex xs12 pt-lg-16>
                <Donation/>
   

            </v-flex>
            <v-flex xs12>
                <StoriesofImpact/>
            </v-flex>
        </v-layout>
     



    </div>
</template>
<script>
import Donation from "./DonationFormNew";
import StoriesofImpact from "./StoriesofImpact";
export default {
    components:{
        Donation,
        StoriesofImpact,
       
    }
    
}   
</script>